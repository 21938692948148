import { twMerge } from '~/lib/utils'

type ContainerProps = {
  className?: string
  children: React.ReactNode
}

export const Container = ({ className, children }: ContainerProps) => {
  return (
    <div className={twMerge('container mx-auto', className)}>{children}</div>
  )
}
